const siteMetadata = {
  title: "P.P. Savani Hospital - Leading Healthcare Facility",
  author: "P.P. Savani Hospital Team",
  description:
    "P.P. Savani Hospital is dedicated to providing advanced medical care and services in the region. Experience compassionate patient care with state-of-the-art infrastructure.",
  language: "en-us",
  siteUrl: "https://www.PPSavaniHospital.in",
  email: "ppsavanihospital@gmail.com",
  phoneNumber: "+91 74358 99929",
  whatsappNumber: "https://wa.me/+917435899929",
  fb: "https://www.facebook.com/maheshsavani9",
  instagram: "https://www.instagram.com/?hl=en",
  twitter: "https://x.com/?mx=2",
  youtube: "https://www.youtube.com/channel/UC8Dr1sElSqX1OgBsLXk7BMQ",
  service: "24*7 Emergency Service",
  locale: "en-US",
  siteName: "P.P. Savani Hospital",
  location:
    "Mansi Building, C/O. P P Savani Hospital, Varachha Main Rd, opposite Kapodra Police Station, Surat.",
  openTime: "MON - SUN : 7:00 AM to 10:00 PM",
  siteLogo: "/assets/logo/logo.png",
};

module.exports = siteMetadata;
