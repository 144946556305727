import { createSlice } from "@reduxjs/toolkit";
import { getSpecialities } from "./specialitiesApi";

// Slice for managing master doctor state
const specialitiesSlice = createSlice({
  name: "specialities",
  initialState: {
    banner: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalBanner: 0,
  },
  extraReducers: (builder) => {
    // GET specialties FILTER WISE DATA
    builder.addCase(getSpecialities.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSpecialities.fulfilled, (state, action) => {
      state.loading = false;
      state.specialities = action.payload;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalSpecialties = action.payload.meta.total;
    });
    builder.addCase(getSpecialities.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default specialitiesSlice.reducer;
