import {
  ic_email,
  ic_fb,
  ic_instagram,
  ic_phone,
  ic_Service,
  ic_twitter,
  ic_youtube,
} from "../assets/layout";
import { SocialLink } from "../utils";
import siteMetadata from "../utils/siteMetaData";

function SocialMedia() {
  return (
    <div className="bg-primary text-white py-2 px-5 max-lg:hidden">
      <div className="flex sm:items-center justify-between gap-4 container mx-auto ">
        <SocialLink
          href={`tel:${siteMetadata.phoneNumber}`}
          icon={ic_Service}
          label="Reach out to me via Phone Number 24*7"
          title="phone Number"
          description={siteMetadata.service}
        />
        <div className="flex gap-4 items-center space-x-6">
          <div className="flex items-center gap-3.5">
            <SocialLink
              href={`mailto:${siteMetadata.email}`}
              icon={ic_email}
              label="Reach out to me via Gmail"
              title="Gmail"
              description={siteMetadata.email}
            />
            <hr className="border border-white h-5" />
            <SocialLink
              href={`tel:${siteMetadata.phoneNumber}`}
              icon={ic_phone}
              label="Reach out to me via Phone Number 24*7"
              title="phone Number"
              description={siteMetadata.phoneNumber}
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="2xl:text-sm text-xs">FOLLOW US :</span>
            <SocialLink
              href={siteMetadata.fb}
              icon={ic_fb}
              label="Reach out to me via Facebook"
              title="Facebook"
            />
            <SocialLink
              href={siteMetadata.instagram}
              icon={ic_instagram}
              label="Reach out to me via instagram"
              title="instagram"
            />
            <SocialLink
              href={siteMetadata.twitter}
              icon={ic_twitter}
              label="Reach out to me via Twitter"
              title="Twitter"
            />
            <SocialLink
              href={siteMetadata.youtube}
              icon={ic_youtube}
              label="Reach out to me via YouTube"
              title="YouTube"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
