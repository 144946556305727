import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

// GET Specialties DATA FILTER WISE
export const getDoctor = createAsyncThunk(
  "Doctors/getAll",
  async (
    {
      queryParams = {},
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const url = id
        ? `${config.base.url}/v1/user/doctor/getDoctor/${id}`
        : `${config.base.url}/v1/user/doctor/getDoctor`;

      const { data } = await axios.get(`${url}`, {
        params: {
          ...queryParams,
          id,
          status,
          search,
          page,
          limit,
          startDate,
          paginate,
          endDate,
        },
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
