import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SocialMedia from "../layout/SocialMedia";

const Home = lazy(() => import("../pages/Home/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const FindDoctors = lazy(() => import("../pages/FindDoctor/DoctorPage"));
const Blogs = lazy(() => import("../pages/Blogs/BlogPage"));
const SingleBlog = lazy(() => import("../pages/Blogs/SingleBlogs"));
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs"));
const PatientProcess = lazy(() =>
  import("../pages/Patient_Process/PatientProcess")
);
const VisitorGuideline = lazy(() =>
  import("../pages/VisitorGuidlines/VisitorGuideline")
);
const ViewDoctorProfile = lazy(() =>
  import("../pages/FindDoctor/ViewDoctorProfile")
);
const BookAppointment = lazy(() =>
  import("../pages/BookAppointment/BookAppointment")
);
const Faq = lazy(() => import("../pages/Faq/Faq"));
const Awards = lazy(() => import("../pages/Awards/Awards"));
const Academics = lazy(() => import("../pages/Academics/Academics"));

const RoutePaths = [
  { path: "/", component: Home, meta: { authRoute: false } },
  { path: "/about", component: AboutUs, meta: { authRoute: false } },
  { path: "/find-doctors", component: FindDoctors, meta: { authRoute: false } },
  { path: "/blogs", component: Blogs, meta: { authRoute: false } },
  /////// SubNav
  { path: "/single-blogs", component: SingleBlog, meta: { authRoute: false } },
  { path: "/contact-us", component: ContactUs, meta: { authRoute: false } },
  {
    path: "/patient-process",
    component: PatientProcess,
    meta: { authRoute: false },
  },
  {
    path: "/visitors",
    component: VisitorGuideline,
    meta: { authRoute: false },
  },
  {
    path: "/view-doctor-profile/:slug",
    component: ViewDoctorProfile,
    meta: { authRoute: false },
  },
  {
    path: "/book-appointment",
    component: BookAppointment,
    meta: { authRoute: false },
  },
  {
    path: "/faq",
    component: Faq,
    meta: { authRoute: false },
  },
  {
    path: "/awards",
    component: Awards,
    meta: { authRoute: false },
  },
  {
    path: "/academics",
    component: Academics,
    meta: { authRoute: false },
  },
];

function Router() {
  return (
    <>
      <SocialMedia />
      <NavBar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {RoutePaths.map((route, index) => {
            const Component = route.component;
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default Router;
