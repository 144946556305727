import { configureStore } from "@reduxjs/toolkit";
import bannerReducer from "../features/Banner/bannerSlice";
import specialitiesReducer from "../features/Specialities/specialitiesSlice";
import doctorReducer from "../features/Doctor/doctorSlice";
import blogReducer from "../features/Blogs/blogSlice";
import videoReducer from "../features/Video/videoSlice";
import appointmentReducer from "../features/BookAppointment/Appointment";

export const store = configureStore({
  reducer: {
    banner: bannerReducer,
    specialities: specialitiesReducer,
    doctor: doctorReducer,
    blogs: blogReducer,
    video: videoReducer,
    appointment: appointmentReducer,
  },
});
