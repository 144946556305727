// NavBar.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ic_ambulance, ic_date, ic_menu } from "../assets/layout";
import { EM_Charitable_trust, pp_savani, pp_savani_group } from "../assets";
import { navItems, additionalItems } from "../lib";
import { cx } from "../utils";
import NavItem from "./index"; // Ensure this component exists

function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const sidebarRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !overlayRef.current.contains(event.target)
      ) {
        setIsNavOpen(false);
      }
    };

    if (isNavOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isNavOpen]);

  return (
    <main>
      {/* Desktop Nav */}
      <header className="lg:grid hidden grid-cols-10 grid-rows-3 border-b border-[#D5D5D5]">
        <div className="col-span-2 row-span-3 flex items-center justify-evenly border-r border-[#D5D5D5]">
          <img
            src={EM_Charitable_trust}
            alt="EM_Charitable_trust"
            width={100}
            height={100}
            className="2xl:w-24 2xl:h-24 h-20 w-20"
          />
          <hr className="h-20 w-[0.4px] bg-primary" />
          <img
            src={pp_savani}
            alt="pp_savani"
            width={80}
            height={80}
            className="2xl:w-24 2xl:h-24 h-20 w-20"
          />
        </div>
        <div className="col-span-7 col-start-3 flex justify-between border-b border-[#D5D5D5] px-5 py-2">
          <div className="flex items-center gap-2">
            <h1 className="font-poppins 2xl:text-sm text-xs text-primary font-semibold">
              Book Online
            </h1>
            <span className="text-secondary font-poppins font-semibold">→</span>
            <p className="text-secondary 2xl:text-sm text-xs font-poppins font-semibold">
              You can request appointment in 24 hours
            </p>
          </div>
          <div className="text-primary text-sm font-medium font-poppins flex items-center gap-5">
            <Link to="/visitors">
              Visitor Guidelines{" "}
              <span className="text-secondary font-bold text-base">+</span>
            </Link>
            <Link to="/patient-process">
              Patients Process{" "}
              <span className="text-secondary font-bold text-base">+</span>
            </Link>
          </div>
        </div>
        <div className="col-start-10 border-b border-[#D5D5D5] py-2">
          <img src={ic_ambulance} alt="ic_ambulance" />
        </div>
        <div className="col-span-7 col-start-3 row-start-2 flex w-fit ml-auto pr-8">
          <div className="text-primary text-sm font-medium  font-poppins flex items-center gap-8 border-b">
            {additionalItems.map(({ text, pathname }) => (
              <NavItem key={text} text={text} pathname={pathname} icon="+" />
            ))}
          </div>
        </div>
        <div className="col-span-7 col-start-3 row-start-3 flex items-center justify-between px-20">
          {navItems.map(({ src, alt, text, icon, pathname }) => {
            return (
              <NavItem
                key={text}
                src={src}
                alt={alt}
                text={text}
                icon={icon}
                pathname={pathname}
              />
            );
          })}
        </div>
        <div className="relative row-span-2 col-start-10 row-start-2 bg-primary font-poppins font-semibold text-white flex justify-center items-center text-center 2xl:text-sm text-xs">
          Book <br /> Appointment
          <img
            src={ic_date}
            alt="ic_date"
            className="absolute left-0 translate-x-[-50%]"
          />
        </div>
      </header>

      {/* Mobile Nav */}
      <nav className="lg:hidden block px-5 bg-white sticky top-0 z-[999999]">
        <div className="flex justify-between items-center gap-3">
          <img
            src={ic_menu}
            alt="ic_menu"
            width={45}
            height={45}
            onClick={() => setIsNavOpen(!isNavOpen)}
          />
          <article className="w-full h-fit px-5 py-2.5 rounded-3xl flex justify-center sm:gap-3 gap-2 items-center">
            {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
              (logo, index) => (
                <React.Fragment key={index}>
                  <img
                    src={logo}
                    alt="pp_savani_group"
                    width={45}
                    height={45}
                  />
                  {index < 2 && <div className="h-6 w-[0.4px] bg-primary" />}
                </React.Fragment>
              )
            )}
          </article>
          <ul className="flex items-end gap-2">
            <li>
              <img
                src={ic_ambulance}
                alt="ic_ambulance"
                width={45}
                height={45}
              />
            </li>
            <li>
              <img src={ic_date} alt="ic_date" width={45} height={45} />
            </li>
          </ul>
        </div>
        <div
          ref={overlayRef}
          className={cx(
            isNavOpen ? "fixed inset-0 bg-black bg-opacity-50" : "hidden",
            "lg:hidden"
          )}
          onClick={() => setIsNavOpen(false)}
        />
        <div
          ref={sidebarRef}
          className={cx(
            isNavOpen
              ? "transform translate-x-0"
              : "transform -translate-x-full",
            "lg:hidden fixed top-0 left-0 z-50 w-64 bg-white h-full transition-transform duration-300 ease-in-out px-4 py-2 border-r border-primary shadow-lg"
          )}
        >
          <article className="bg-white w-full h-fit rounded-3xl flex justify-between sm:gap-3 gap-2 items-center my-5">
            {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
              (logo, index) => (
                <React.Fragment key={index}>
                  <img
                    src={logo}
                    alt="pp_savani_group"
                    width={45}
                    height={45}
                  />
                  {index < 2 && <div className="h-6 w-[0.4px] bg-primary" />}
                </React.Fragment>
              )
            )}
          </article>
          <div className="flex flex-col items-start mt-2">
            {navItems.map(({ src, alt, text, icon, pathname }) => (
              <div key={text} className="border-b py-2 w-full">
                <NavItem
                  src={src}
                  alt={alt}
                  text={text}
                  icon={icon}
                  pathname={pathname}
                />
              </div>
            ))}
          </div>
          <hr className="my-3 border-secondary" />
          <div className="flex flex-col items-start mt-3">
            {additionalItems.map(({ text, pathname }) => (
              <div key={text} className="border-b py-2 w-full">
                <NavItem text={text} pathname={pathname} icon="+" />
              </div>
            ))}
          </div>
          <hr className="my-3 border-secondary" />
          <div className="text-primary text-sm font-medium flex flex-col items-start">
            <Link
              to="/#"
              className="2xl:text-sm text-xs text-primary font-semibold border-b py-2 w-full"
            >
              Patients Process <span className="text-secondary">+</span>
            </Link>
            <Link
              to="/#"
              className="2xl:text-sm text-xs text-primary font-semibold border-b py-2 w-full"
            >
              Visitor Guidelines <span className="text-secondary">+</span>
            </Link>
          </div>
        </div>
      </nav>
    </main>
  );
}

export default NavBar;
