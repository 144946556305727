import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

const baseURL = process.env.REACT_APP_BASE_URL_LOCAL;

export const getSpecialities = createAsyncThunk(
  "Specialities/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/specialities/getSpecialties`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Specialities"
      );
    }
  }
);

export const getDoctorsBySpecialty = createAsyncThunk(
  "appointments/getDoctorsBySpecialty",
  async (specialtyId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/specialty/${specialtyId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch doctors");
    }
  }
);

export const getAvailableDays = createAsyncThunk(
  "appointments/getAvailableDays",
  async (doctorId, { rejectWithValue }) => {
    console.log(doctorId, "=======doctorId");
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/days/${doctorId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available days");
    }
  }
);

export const getAvailableDates = createAsyncThunk(
  "appointments/getAvailableDates",
  async (day, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/dates/${day}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available dates");
    }
  }
);

export const getAvailableTimes = createAsyncThunk(
  "appointments/getAvailableTimes",
  async ({ doctorId, date }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/times/${doctorId}/${date}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available times");
    }
  }
);

// Slice
const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    specialities: [],
    doctors: [],
    availableDays: [],
    availableDates: [],
    availableTimes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Specialities
    builder.addCase(getSpecialities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSpecialities.fulfilled, (state, action) => {
      state.loading = false;
      state.specialities = action.payload;
    });
    builder.addCase(getSpecialities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Doctors by specialty
    builder.addCase(getDoctorsBySpecialty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDoctorsBySpecialty.fulfilled, (state, action) => {
      state.loading = false;
      state.doctors = action.payload;
    });
    builder.addCase(getDoctorsBySpecialty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available days
    builder.addCase(getAvailableDays.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableDays.fulfilled, (state, action) => {
      state.loading = false;
      state.availableDays = action.payload;
    });
    builder.addCase(getAvailableDays.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available dates
    builder.addCase(getAvailableDates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableDates.fulfilled, (state, action) => {
      state.loading = false;
      state.availableDates = action.payload;
    });
    builder.addCase(getAvailableDates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available times
    builder.addCase(getAvailableTimes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableTimes.fulfilled, (state, action) => {
      state.loading = false;
      state.availableTimes = action.payload;
    });
    builder.addCase(getAvailableTimes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Export actions and reducer
export default appointmentsSlice.reducer;
