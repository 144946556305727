import { createSlice } from "@reduxjs/toolkit";
import { getDoctor } from "./doctorApi";

// Slice for managing master doctor state
const doctorSlice = createSlice({
  name: "doctor",
  initialState: {
    doctor: [],
    selectedDoctor: null,
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalDoctor: 0,
  },
  reducers: {
    clearSelectedDoctor: (state) => {
      state.selectedDoctor = null;
    },
  },

  extraReducers: (builder) => {
    // GET specialties FILTER WISE DATA
    builder.addCase(getDoctor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDoctor.fulfilled, (state, action) => {
      if (action.meta.arg.id) {
        state.loading = false;
        state.selectedDoctor = action.payload;
      } else {
        state.loading = false;
        state.doctor = action.payload.data;
        state.dataLimit = action.payload.meta.limit;
        state.currentPage = action.payload.meta.page;
        state.totalPages = action.payload.meta.totalPages;
        state.totalDoctor = action.payload.meta.total;
      }
    });
    builder.addCase(getDoctor.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default doctorSlice.reducer;
