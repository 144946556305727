import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ src, alt, text, icon, pathname }) => {
  return (
    <div className="flex items-center gap-2">
      {src && <img src={src} alt={alt} />}
      <Link
        to={pathname}
        className="2xl:text-base text-xs text-primary font-semibold"
      >
        {text}{" "}
        {icon && (
          <span className="text-secondary font-bold text-base">{icon}</span>
        )}
      </Link>
    </div>
  );
};

export default NavItem;
