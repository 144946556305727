import { createSlice } from "@reduxjs/toolkit";
import { getVideo } from "./videoApi";

// Slice for managing master doctor state
const videoSlice = createSlice({
  name: "videos",
  initialState: {
    blogs: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalSpecialties: 0,
  },
  extraReducers: (builder) => {
    // GET specialties FILTER WISE DATA
    builder.addCase(getVideo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVideo.fulfilled, (state, action) => {
      state.loading = false;
      state.videos = action.payload;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalVideo = action.payload.meta.total;
    });
    builder.addCase(getVideo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default videoSlice.reducer;
