import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

export const getSpecialities = createAsyncThunk(
  "Specialities/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/specialities/getSpecialties`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Specialities"
      );
    }
  }
);
